<template>
  <div class="padd_content">
    <v-card class="radius-card custom_card pa-5">
      <div class="toolbar d-flex">
        <div style="width: 70%">
          <v-row>
            <v-col cols="7">
              <v-text-field
                flat
                label="Cari Document"
                class="custom_field"
                background-color="#F1FBFC"
                solo
                @keydown="allFilter"
                v-model="find"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                color="blue"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="170"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    height="100%"
                  >
                    <v-icon>mdi-filter-outline</v-icon>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-card>
                </template>
                <v-card class="pa-2 popup_radius" outlined elevation="0">
                  <p class="color_txt">Filter</p>
                  <v-radio-group
                    dense
                    color="info"
                    hide-details="auto"
                    v-model="role"
                  >
                    <v-radio label="Semua" value="kosong"></v-radio>
                    <v-radio label="Paparan" value="Paparan"></v-radio>
                    <v-radio
                      label="Peraturan"
                      value="Peraturan"
                    ></v-radio>
                    <v-radio
                      label="Pedoman"
                      value="Pedoman"
                    ></v-radio>
                  </v-radio-group>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="txt-capitalize"
                      color="#00b4cc"
                      dark
                      depressed
                      width="100px"
                      @click="filterRole()"
                    >
                      done
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="menuLimit"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    height="100%"
                  >
                    <p class="ma-0 mr-2 color_txt small_txt">
                      Show : {{ limit }}
                    </p>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>
                <v-card class="popup_radius" outlined elevation="0">
                  <v-list>
                    <v-list-item link @click="changeLimit(10)">
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(50)">
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(100)">
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-end align-center" style="width: 30%">
          <v-btn
            depressed
            rounded
            dark
            color="#00b4cc"
            class="text-capitalize"
            @click="dialogCreate = true"
            ><v-icon class="mr-2">mdi-plus</v-icon>
            Tambah File</v-btn
          >
        </div>
      </div>
      <v-skeleton-loader type="table-tbody" v-if="loading"></v-skeleton-loader>

      <div class="mt-5" v-if="mutatedUser">
        <v-data-table
          :headers="headers"
          :items="mutatedUser"
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :single-select="false"
        >
          <!-- <template v-slot:[`item.isActive`]="{ item }">
            <v-switch
              v-if="item.isActive"
              hide-details="auto"
              class="mt-0"
              inset
              @click.stop="changeStatus(item)"
              v-model="item.status"
            ></v-switch>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <p class="ma-0 grey--text">
              {{ item.isActive == "NonActive" ? "Tidak Aktif" : "Aktif" }}
            </p>
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item">
              <v-btn
                small
                depressed
                color="#00ccb6"
                class="mr-2 action_btn"
                min-width="10px"
                dark
                :href="item.path"
                target="_blank"
                ><v-icon>mdi-link-variant</v-icon></v-btn
              >
              <v-btn
                small
                depressed
                color="#49CADA"
                class="mr-2 action_btn"
                min-width="10px"
                dark
                @click="viewUser(item)"
                ><v-icon>mdi-pencil-outline</v-icon></v-btn
              >
              <v-btn
                small
                depressed
                color="#00879a"
                class="mr-2 px-1"
                min-width="10px"
                dark
                @click="delFile(item)"
              >
                <v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </div>
          </template>
        </v-data-table>
        <div class=" d-flex justify-center pa-3">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
              ><v-icon small>mdi-arrow-left</v-icon></v-btn
            >
            <div class="mx-3">
              <p class="ma-0 color_default">{{ page }} of {{ total_page }}</p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
              ><v-icon small>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <TambahFile
        v-bind:dialogCreate="dialogCreate"
        @close="dialogCreate = false"
        @refetch="refetch()"
      />
      <EditFile
        v-bind:dialogUpdate="dialogUpdate"
        @close="dialogUpdate = false"
        v-bind:dataFile="dataFile"
        @refetch="refetch()"
      />
      <DeleteFile
        v-bind:dialogDeleteFile="dialogDeleteFile"
        v-bind:dataFile="dataFile"
        @refetch="refetch()"
        @close="dialogDeleteFile = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TambahFile from "../../../components/document/tambahFile.vue";
import EditFile from "../../../components/document/updateFile.vue";
import DeleteFile from "../../../components/document/deleteFile.vue";
export default {
  name: "AllDocument",
  computed: {
    ...mapState({
      all_document: (state) => state.document.all_document,
    }),
    mutatedUser() {
      let data = null;
      if (this.all_document) {
        data = this.all_document.data.data;
      }
      return data;
    },
  },

  components: {
    TambahFile,
    DeleteFile,
    EditFile,
  },
  data() {
    return {
      loading: false,
      dialogUpdate: false,
      dataUser: null,
      dataFile: null,
      role: "kosong",
      page: 1,
      limit: 10,
      find: "",
      menu: false,
      menuLimit: false,
      dialogDeleteUser: false,
      dialogDeleteFile: false,
      dialogCreate: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headers: [
        {
          text: "Nama File",
          align: "start",
          value: "title",
        },
        {
          text: "Kategori",
          align: "start",
          value: "category",
        },
        { text: "Aksi", sortable: false, value: "actions" },
      ],
      isNext: "",
      isPrev: "",
      total_page: null,
    };
  },
  mounted() {
    this.allFilter();
    this.getAllDocument()
  },
  methods: {
    getAllDocument() {
      this.$store.dispatch('document/getDocument')
    },
    refetch() {
      this.allFilter();
      this.dialogCreate = false;
    },

    getUser() {
      this.loading = true;
      this.$store
        .dispatch("user/getUser")
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    filterRole() {
      this.menu = false;
      let body = {
        kategori: this.role == 'kosong' ? '' : this.role,
        find: this.find
      };
      let data = {
        status: null,
        body: body,
      };
      this.loading = true;
      this.$store
        .dispatch("document/filterRole", data)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.allFilter("limit");
    },
    pagination(state) {
      console.log(state, 'asd');
      if (state == "next") {
        if (this.isNext) {
          this.page++;
          this.allFilter();
        }
      } else {
        if (this.isPrev) {
          this.page--;
          this.allFilter();
        }
      }
    },
    allFilter(type) {
      this.loading = true;
      this.page = type == "limit" ? 1 : this.page;
      this.menu = false;
      let body = {
        role: this.role == 'kosong' ? '' : this.role,
        find: this.find,
        limit: this.limit,
        page: this.page,
      };
      let data = {
        status: null,
        body: body,
      };
      this.$store
        .dispatch('document/filterAllinOne', data)
        .then((data) => {
          this.loading = false;
          this.isNext = data.data.data.next_page_url;
          this.isPrev = data.data.data.prev_page_url;
          this.total_page = Math.ceil(data.data.data.total / this.limit);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    delFile(item) {
      console.log(item);
      this.dataFile = item;
      this.dialogDeleteFile = true;
    },
    changeStatus(item) {
      this.dataUser = item;
      this.dialogChangeStatus = true;
    },
    viewUser(item) {
      console.log(item);
      this.dataFile = item;
      this.dialogUpdate = true;
      // this.$refs.update.getPerson(item.id);
    },
    row_classes(item) {
      let idx = this.all_document.data.data.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
  },
};
</script>

<style scoped>
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}
.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
</style> 