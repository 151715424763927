<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCreate" scrollable persistent max-width="500px">
      <v-card class="rounded-max hide_overflow" flat>
        <form action="" style="overflow-y: auto">
          <div>
            <div class="closer d-flex justify-end pa-1">
              <v-btn icon small @click="$emit('close')">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
            <h3 class="px-10 color_default mb-5" >Tambah File</h3>
            <div class="py-3 px-10" >
              <v-row>
                <v-col cols="12">
                  <label for="judul" class="color_txt">Judul</label>
                  <v-text-field
                    v-model="judul"
                    color="#00b4cc"
                    dense
                    placeholder="Ketik disini"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="kategori" class="color_txt">Kategori</label>
                  <v-select
                    placeholder="Pilih Kategori"
                    item-text="kategori"
                    item-value="kategori"
                    v-model="kategori"
                    :items="category"
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12">
                  <label for="roleaccess" class="color_txt">Akses</label>
                  <v-select
                    placeholder="Pilih Akses"
                    item-text="akses"
                    item-value="akses"
                    v-model="roleaccess"
                    :items="akses"
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col> -->
                <v-col cols="12">
                  <label for="link" class="color_txt">Link</label>
                  <v-text-field
                    v-model="link"
                    color="#00b4cc"
                    dense
                    placeholder="URL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="typefile" class="color_txt">Type file</label>
                  <v-select
                    placeholder="Pilih Akses"
                    item-text="akses"
                    item-value="akses"
                    v-model="typefile"
                    :items="files"
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-card-actions class="py-3 px-7 mb-5">
            <v-spacer></v-spacer>
            <div>
              <div >
                <v-btn
                  v-if="!loading"
                  color="#00b4cc"
                  width="100px"
                  dark
                  class="text-capitalize"
                  @click="tambahFiles"
                  >Tambah</v-btn
                >
                <v-btn
                  v-if="loading"
                  color="#00b4cc"
                  dark
                  class="text-capitalize"
                >
                  <v-progress-circular
                    indeterminate
                    class="mr-2"
                    color="white"
                    size="20"
                  ></v-progress-circular>
                  loading...</v-btn
                >
              </div>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "dialogCreate",
  props: ["dialogCreate"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
      province: (state) => state.region.province,
      city: (state) => state.region.city,
    }),
  },
  data() {
    return {
      files: ['DOC', 'PDF'],
      judul: null,
      kategori: '',
      link: '',
      loading: false,
      roleaccess: '',
      typefile: '',
      category: ['Paparan', 'Peraturan', 'Pedoman'],
      akses: ['Kemenkes', 'Dinkes Provinsi', 'Dinkes Kabupaten/Kota', 'Fasyankes (Rumah Sakit/Puskesmas)']
      // akses: ['Administrator', 'Fasyankes', 'Kemenkes', 'Dinkes Provinsi', 'Dinkes Kab/Kota']
    };
  },
  mounted() {
  },
  methods: {
    tambahFiles() {
      if(this.judul && this.kategori && this.link && this.typefile) {

        let data = {
          title: this.judul,
          category: this.kategori,
          roleaccess: this.roleaccess,
          path: this.link,
          typefile: this.typefile
        };
        this.loading = true;
        this.$store
          .dispatch("document/tambahDocument", data)
          .then((data) => {
            this.loading = false;
            this.$emit("refetch");
            this.Swal("success", data.message);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.Swal("error", err.message);
          });
      } else {
        this.Swal("error", "Lengkapi Form!");
      }
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
    checkForm(type) {
      console.log(type)
    },
    validateStep() {
      if (this.step == 1) {
        if (this.name !== "" && this.number !== "" && this.role !== "") {
          this.nextStep();
        } else {
          this.Swal("error", "Lengkapi Form!");
        }
      } else if (this.step == 2) {
        if (this.role == "Kemenkes") {
          if (this.instansi !== "" && this.jabatan !== "") {
            this.nextStep();
          } else {
            this.Swal("error", "Lengkapi Form!");
          }
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      this.step++;
    },
    backStep() {
      this.step--;
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>
